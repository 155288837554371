import * as React from 'react';
import { namedStyled } from '@naan/stitches.config';
import { VideoModalCard } from './video-modal-card';
import { SpinnerIcon } from 'components/ui/icons/spinner-icon';
import { PlayVideoIcon } from './play-video-icon';
import { VideoMetaDescription } from './video-meta-description';
import { namedObserver } from 'lib/named-observer';
import { VideoPlayerModel } from './video-player-model';
import { PlayerViewController } from 'components/ui/video-player/player-view-controller';

const PreviewCardWrapper = namedStyled('PreviewCardWrapper', VideoModalCard, {
  $$landscapeImage: '',
  $$portraitImage: '',
  $$image: '$$portraitImage',
  backgroundColor: '$blue-700',
  zIndex: 3,
  backgroundSize: 'cover',
  backgroundImage: `$$image`,
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@medium': {
    $$image: '$$landscapeImage',
  },

  '& > .play-button': {
    '--play-video-button-bg': '$colors$teal-500',
    all: 'unset',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    '&:hover': {
      '--play-video-button-bg': '$colors$teal-600',
    },
  },
  '& > .description-container': {
    position: 'absolute',
    zIndex: 2,
    left: 16,
    bottom: 'calc(16px + var(--sab))',
    '@medium': {
      bottom: 16,
    },
  },
});

export const PreviewCard = namedObserver(
  'PreviewCard',
  ({
    model,
    player,
  }: {
    model: VideoPlayerModel;
    player: PlayerViewController;
  }) => {
    // const playVideo = () => {
    //   wistiaState.playVideo();
    //   model.setWidgetState(VideoGuideWidgetStates.PLAYER);
    // };

    const video = model.current;
    if (!video) {
      return null;
    }

    return (
      <PreviewCardWrapper
        className="preview-card-wrapper"
        css={{
          $$landscapeImage: `url(${video.imageUrlLandscape})`,
          // $$portraitImage: `url(${video.imageUrlPortrait})`,
        }}
        onTop
      >
        {player.canPlay ? (
          <button className="play-button" onClick={() => player.play()}>
            <PlayVideoIcon />
          </button>
        ) : (
          <SpinnerIcon />
        )}
        <div className="description-container">
          <VideoMetaDescription>{video.title}</VideoMetaDescription>
        </div>
      </PreviewCardWrapper>
    );
  }
);
