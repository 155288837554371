import * as React from 'react';

import {
  Root,
  Portal,
  Trigger as UnstyledTrigger,
  DialogTitle,
  DialogDescription,
} from '@radix-ui/react-dialog';
import { styled, darkThemeSelector } from 'naan/stitches.config';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { Responsive } from '@naan/primitives/responsive';
import { Content, SwipeableContent } from './popover-content';
import { elementIds } from 'components/dom-utils/element-ids';
import { getElementById } from 'components/dom-utils';

import { createLogger } from '@common/log';

const log = createLogger('nav-popover-menu');

const Trigger = styled(UnstyledTrigger, {
  all: 'unset',
});

const Wrapper = styled('div', {
  // background: '$white',
  height: '100%',
  display: 'grid',
  gridTemplateRows: '1fr calc(60px + (var(--sab)))',
  gridTemplateColumns: '1fr',
  '.content-area': {
    paddingTop: 'var(--sat)',
    width: '100%',
    gridRow: '1 / 2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'auto',
    '@large': {
      paddingTop: 0,
    },
  },

  '@large': {
    display: 'flex',
    position: 'relative',
  },

  '.close-btn-area': {
    gridRow: '2 / 3',
    borderTop: '1px solid $colors$gray-100',
    background: '$colors$gray-25',
    paddingBottom: 'var(--sab)',

    '@large': {
      display: 'none',
    },

    '& button': {
      textStyle: 'body-bold',
      color: '$colors$textSecondary',
      padding: '18px 0 calc(18px + var(--sab))',
      width: '100%',
      border: 'none',
      background: 'transparent',
    },
  },

  '& .arrow': {
    display: 'none',

    '@large': {
      display: 'block',
      overflow: 'hidden',
      width: 24,
      height: 16,
      left: 16,
      bottom: -16,
      position: 'absolute',
      '&::before': {
        content: '""',
        display: 'block',
        width: '0',
        height: '0',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '12px solid $colors$white',
        filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2))',
        [darkThemeSelector]: {
          borderTopColor: '$colors$gray-50',
          filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5))',
        },
      },
    },
  },

  '& .external': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '12px',
    marginTop: 'auto',
    '@large': {
      display: 'none',
    },
  },
});

const PopoverContext = React.createContext<{
  open: boolean;
  close: () => void;
}>({
  open: false,
  close: () => {},
});

export const usePopoverContext = () => {
  return React.useContext(PopoverContext);
};

const MenuContent = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <>
      <VisuallyHidden>
        <DialogTitle>options</DialogTitle>
        <DialogDescription>account options</DialogDescription>
      </VisuallyHidden>
      <Wrapper>
        <div className="content-area">{children}</div>
        <div className="close-btn-area">
          <button onClick={onClose}>Close</button>
        </div>
        <div className="arrow" />
      </Wrapper>
    </>
  );
};

export const NavPopoverMenu = ({
  children,
  trigger,
}: {
  children: React.ReactNode;
  trigger: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = React.useCallback(async () => {
    const element = getElementById(elementIds.POPOVER_CONTENT);
    if (element) {
      // set [data-state="closed"]
      element.setAttribute('data-state', 'closed');
      // wait for animation to finish
      // element.addEventListener('animationend', () => {
      //   setOpen(false);
      // });
      await new Promise(resolve => {
        setTimeout(() => {
          setOpen(false);
          resolve(true);
        }, 300);
      });
    } else {
      log.error('element not found', elementIds.POPOVER_CONTENT);
      // setOpen(false);
    }
  }, [setOpen]);

  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (!open) {
        void handleClose();
      } else {
        setOpen(true);
      }
    },
    [handleClose, setOpen]
  );

  return (
    <PopoverContext.Provider value={{ open, close: handleClose }}>
      <Root open={open} onOpenChange={handleOpenChange}>
        <Trigger>{trigger}</Trigger>
        <Portal>
          <Responsive
            renderDefault={() => (
              <SwipeableContent isOpen={open} onOpenChange={setOpen}>
                <MenuContent onClose={handleClose}>{children}</MenuContent>
              </SwipeableContent>
            )}
            renderLargeAndUp={() => (
              <Content id={elementIds.POPOVER_CONTENT}>
                <MenuContent onClose={handleClose}>{children}</MenuContent>
              </Content>
            )}
          />
        </Portal>
      </Root>
    </PopoverContext.Provider>
  );
};
