import { createLogger } from '@common/log';

const log = createLogger('init-embed-state');

// eslint-disable-next-line no-console
// console.log('init-embedded-state.ts');
log.debug('init-embedded-state.ts');

interface EmbedState {
  platform: string;
  buildNum: number;
  insetTop: number;
  insetBottom: number;
  error?: any; // preserve any error for delayed reporting
  empty?: boolean;
  pendingReload?: boolean;
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ReactNativeWebView: any;
    embedState: EmbedState;
  }
}

const EMPTY_STATE: EmbedState = {
  platform: undefined as string,
  buildNum: 0,
  insetTop: 0,
  insetBottom: 0,
  empty: true, // distinguish
};

const EMBED_STATE_KEY = 'jw-embed-state-v2';

const getFromQuery = (): EmbedState | null => {
  const searchParams = new URLSearchParams(window.location.search);
  const platform = searchParams.get('embeddedPlatform');
  const buildNum = parseInt(searchParams.get('embeddedBuildNumber') || '0');
  const insetTop = parseInt(searchParams.get('insetTop') || '0');
  const insetBottom = parseInt(searchParams.get('insetBottom') || '0');

  // using 'embeddedPlatform' existence as the test to know if we embedded or not
  // and the need to persist the local storage state
  if (platform) {
    return { platform, buildNum, insetTop, insetBottom };
  } else {
    return null;
  }
};

const getFromLocalStorage = (): EmbedState | null => {
  const str = localStorage.getItem(EMBED_STATE_KEY);
  if (str) {
    return JSON.parse(str) as EmbedState;
  } else {
    return null;
  }
};

const saveToLocalStorage = (params: EmbedState) => {
  const str = JSON.stringify(params);
  localStorage.setItem(EMBED_STATE_KEY, str);
  // log.debug(`saveToLocalStorage: ${str}`);
  // eslint-disable-next-line no-console
  // console.log('saveToLocalStorage', str);
  log.debug('saveToLocalStorage', str);
};

const resolveState = (): EmbedState => {
  try {
    migrateV80InsetState(); // todo: remove this once sufficiently past 8.1 release
    const queryParams = getFromQuery();

    if (queryParams) {
      saveToLocalStorage(queryParams);
      if (queryParams.platform === 'ios') {
        // hack attempt to fix sw.controller when embedded by reloading at least once after a cold start
        // setTimeout(() => {
        //   const baseUrl = window.location.href.split('?')[0];
        //   window.location.href = baseUrl;
        // }, 1000);
        // eslint-disable-next-line no-console
        // console.log('setting pendingReload');
        // can't set this yet
        // log.debug('setting pendingReload');
        // window.embedState.pendingReload = true;
      }
      return queryParams;
    }
    const storedState = getFromLocalStorage();
    if (storedState) {
      return storedState;
    } else {
      return EMPTY_STATE;
    }
  } catch (error) {
    // don't let this logic be fatal
    // eslint-disable-next-line no-console
    console.error(`error resolving embedded params`, error);
    // todo: are error logging dependencies safe / justified here?
    // bugsnagNotify(error as Error);
    return { error, ...EMPTY_STATE };
  }
};

// // to be called from the app-root.init to check for need to reload,
// // since reloading during the module init was too early
// export const embeddedIosColdstart = () => {
//   const searchParams = new URLSearchParams(window.location.search);
//   const platform = searchParams.get('embeddedPlatform');
//   return platform === 'ios';
// };

const initState = () => {
  const state = resolveState();
  window.embedState = state;
  return state;
};

// immediately invoke
initState();

//
// dev-tools support
//
export const saveEmbedStateToLocalStorage = saveToLocalStorage;

export const clearEmbedState = () => {
  localStorage.removeItem(EMBED_STATE_KEY);
  window.embedState = EMPTY_STATE;
};

//
// handle migration for v8.0 persisted inset state to v8.1
//

function migrateV80InsetState() {
  const v80Insets = getV80InsetsFromLocalStorage();
  if (v80Insets) {
    saveToLocalStorage({
      insetTop: v80Insets.top,
      insetBottom: v80Insets.bottom,
      // these will get migrated from appRoot.localState during app init
      platform: undefined,
      buildNum: 0,
    });
    removeV80InsetsLocalStorage();
  }
}

function getV80InsetsFromLocalStorage(): {
  top: number;
  bottom: number;
} | null {
  const insetsString = localStorage.getItem('insets');
  if (insetsString) {
    const [insetTop, insetBottom] = insetsString
      .split(',')
      .map(inset => parseInt(inset));
    return { top: insetTop, bottom: insetBottom };
  }
  return null;
}

function removeV80InsetsLocalStorage() {
  localStorage.removeItem('insets');
}
