import * as React from 'react';

import { CloseIcon } from '@naan/icons/close-icon';
import { PlayTriangleIcon } from '@naan/icons/play-triangle-icon';
import { RewindIcon } from '@naan/icons/rewind-icon';
import { styled } from '@naan/stitches.config';
import { PlayerViewController } from 'components/ui/video-player/player-view-controller';
import { observer } from 'mobx-react';
import { OverlayButton } from 'components/ui/video-player/video-player2/overlay-button';
import { Button } from '@naan/primitives/button';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  padding: 'calc(48px + var(--sat)) 48px calc(48px + var(--sab))',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.40)',
  backdropFilter: 'blur(6px)',
  zIndex: 2,

  '& > .primary-buttons': {
    width: '100%',
    maxWidth: 320,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },

  '& > .skip-button': {
    position: 'absolute',
    bottom: 'calc(16px + var(--sab))',
    '@fixedRatioVideoPlayer': {
      bottom: 16,
    },
    right: 16,
  },

  '& > .complete-button': {
    position: 'absolute',
    bottom: 16,
    right: 16,
    left: 16,
  },
});

export const PausedOverlay = observer(
  ({
    player,
    videoWatched,
    onExit,
    onSkip,
    onPlay,
    onRestart,
  }: {
    player: PlayerViewController;
    videoWatched: boolean;
    onExit: () => void;
    onSkip: () => void;
    onPlay: () => void;
    onRestart: () => void;
  }) => {
    return (
      <Wrapper className="paused-overlay">
        <div className="primary-buttons">
          <OverlayButton
            presentation={'teal'}
            leftIcon={<PlayTriangleIcon />}
            label={__('Resume', 'resume')}
            onClick={() => {
              onPlay();
            }}
          />
          <OverlayButton
            leftIcon={<RewindIcon />}
            label={__('Restart', 'restart')}
            onClick={() => {
              onRestart();
            }}
          />
          <OverlayButton
            leftIcon={<CloseIcon />}
            label={__('Exit', 'exit')}
            onClick={() => {
              player.videoElement.pause();
              onExit();
            }}
          />
        </div>
        <div className="skip-button">
          <Button
            presentation={videoWatched ? 'yellow' : 'whiteDarken'}
            size="small"
            label={__('Skip video', 'skipVideo')}
            onClick={() => {
              onSkip();
            }}
          />
        </div>
      </Wrapper>
    );
  }
);
