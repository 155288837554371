import * as React from 'react';

import { styled } from '@naan/stitches.config';
import {
  PlayerViewController,
  PlayerViewControllerFactory,
} from 'components/ui/video-player/player-view-controller';
import { observer } from 'mobx-react';
import { ProgressBar } from 'components/ui/video-player/video-player2/progress-bar';
import { PausedOverlay } from './paused-overlay';
import { NotStartedOverlay } from './not-started-overlay';
import { EndOverlay } from './end-overlay';

import { createLogger } from '@common/log';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';
import { hidePiMenu } from 'components/pi-menu';
const log = createLogger('video-player');

const Backdrop = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  background:
    'radial-gradient(66.5% 66.5% at 50% 50%, #2D597E 0%, #203D59 100%)',
  '@fixedRatioVideoPlayer': {
    padding: 'var(--sat) 0 var(--sab)',
  },
});

const VideoWrapper = styled('div', {
  overflow: 'hidden',
  display: 'flex',
  placeItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',

  '& > video': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    padding: 0,
    zIndex: 1,
  },

  '& > .loading': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '& > .progress-bar': {
    position: 'absolute',
    right: 16,
    top: 'calc(16px + var(--sat))',
    '@fixedRatioVideoPlayer': {
      top: 16,
    },
    zIndex: 4,
  },

  '& > .pause-button': {
    position: 'absolute',
    left: 16,
    top: 'calc(16px + var(--sat))',
    '@fixedRatioVideoPlayer': {
      top: 16,
    },
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    backdropFilter: 'blur(8px)',

    '& > button': {
      all: 'unset',
    },
  },

  // '& > .subtitles': {
  //   fontFamily: 'larsseit-bold',
  //   fontWeight: '400',
  //   fontSize: '22px',
  //   lineHeight: '28px',
  //   webkitFontSmoothing: 'antialiased',
  //   zIndex: 3,

  //   display: 'none',
  //   position: 'absolute',
  //   maxWidth: 'calc(100% - 60px)',
  //   paddingBlock: 6,
  //   paddingInline: 12,
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   bottom: 'calc(22px + var(--sab))',
  //   borderRadius: '$radii$large',
  //   color: 'white',
  //   backgroundColor: 'rgba(50,50,50,.75)',
  //   textAlign: 'center',
  //   '&.show': {
  //     display: 'block',
  //   },
  // },

  '& video::cue': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '28px',
    webkitFontSmoothing: 'antialiased',
    color: 'white',
    backgroundColor: 'rgba(50,50,50,.75)',
  },

  // any screen that would end up with too square, too tall or too huge a video gets the desktop layout
  '@fixedRatioVideoPlayer': {
    aspectRatio: '2 / 3',
    width: 'auto',
    // boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.20)',
    shadowStyle: 'medium',
    background: 'transparent',
    // 801 here is so we get round numbers on desktop (534 x 801)
    height: 'min(801px, calc(100vh - 32px - var(--sat) - var(--sab)))',
    maxWidth: 'calc(100vw - 32px)',
    borderRadius: '$radii$large',
  },
});

const playerController = PlayerViewControllerFactory();

const PauseIcon = () => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.5" cx="20" cy="20" r="20" fill="#808080" />
    <path
      d="M17.6922 12.3076C18.117 12.3076 18.4614 12.652 18.4614 13.0768V26.923C18.4614 27.3478 18.117 27.6922 17.6922 27.6922H15.3845C14.9596 27.6922 14.6152 27.3478 14.6152 26.923V13.0768C14.6152 12.652 14.9596 12.3076 15.3845 12.3076H17.6922ZM24.6152 12.3076C25.0401 12.3076 25.3845 12.652 25.3845 13.0768V26.923C25.3845 27.3478 25.0401 27.6922 24.6152 27.6922H22.3075C21.8827 27.6922 21.5383 27.3478 21.5383 26.923V13.0768C21.5383 12.652 21.8827 12.3076 22.3075 12.3076H24.6152Z"
      fill="#fff"
    />
  </svg>
);

type OverlayCardType =
  | 'not-started'
  | 'playing-overlay'
  | 'end-overlay'
  | 'paused-overlay'
  | 'loading-overlay';

function getCard(
  playerController: PlayerViewController,
  videoWatched: boolean
): OverlayCardType {
  // if (!playerController.canPlay) {
  //   return 'loading-overlay';
  // }

  if (playerController.isNotStarted) {
    return 'not-started';
  }

  if (playerController.isPlaying) {
    if (!playerController.canPlay) {
      return 'loading-overlay';
    }

    return 'playing-overlay';
  }

  // @armando, can't we assume the video is watched if we're in the ended state?
  // right now 'videoWatched' is undesireably false because this gets evaluated before the achievement state is updated
  if (playerController.isEnded /*&& videoWatched */) {
    return 'end-overlay';
  }

  if (playerController.canPlay) {
    return 'paused-overlay';
  }

  return 'loading-overlay';
}

const PlayerOverlayCard = observer(
  ({
    title,
    poster,
    playerController,
    videoWatched,
    onComplete,
    onExit,
    onSkip,
  }: {
    title: string;
    poster: string;
    playerController: PlayerViewController;
    videoWatched: boolean;
    onComplete: () => void;
    onExit: () => void;
    onSkip?: () => void;
  }) => {
    const card = getCard(playerController, videoWatched);

    const handlePlay = React.useCallback(() => {
      track(events.onboardingVideos.startVideo, {
        video: title,
      });
      playerController.play();
    }, [playerController, title]);

    log.info('Rendering card', card);

    const handleRestart = React.useCallback(() => {
      track(events.onboardingVideos.restartVideo, {
        video: title,
      });
      playerController.videoElement.currentTime = 0;
      void playerController.videoElement.play();
    }, [playerController, title]);
    // debugger;

    switch (card) {
      case 'loading-overlay':
      case 'not-started':
        return (
          <NotStartedOverlay
            title={title}
            poster={poster}
            player={playerController}
            onExit={onExit}
            onPlay={handlePlay}
            loading={card === 'loading-overlay'}
          />
        );
      case 'playing-overlay':
        return (
          <div className="pause-button">
            <button onClick={() => playerController.videoElement.pause()}>
              <PauseIcon />
            </button>
          </div>
        );
      case 'end-overlay':
        return (
          <EndOverlay
            player={playerController}
            onComplete={onComplete}
            onRestart={handleRestart}
            onExit={onExit}
          />
        );
      case 'paused-overlay':
        return (
          <PausedOverlay
            videoWatched={videoWatched}
            player={playerController}
            onExit={onExit}
            onSkip={onSkip}
            onPlay={handlePlay}
            onRestart={handleRestart}
          />
        );
    }
  }
);

export const VideoPlayer2 = ({
  videoSrc,
  onComplete,
  onEnded,
  onExit,
  // onSkip,
  videoWatchedFn,
  title,
  poster,
}: {
  title: string;
  poster: string;
  videoSrc: string;
  onEnded: () => void;
  onComplete: () => void;
  onExit: () => void;
  // onSkip?: () => void;
  videoWatchedFn?: () => boolean;
}) => {
  const videoWatched = videoWatchedFn ? videoWatchedFn() : false;
  const videoElementRef = React.useRef<HTMLVideoElement>(null);

  const onSkip = React.useCallback(() => {
    track(events.onboardingVideos.skipVideo, {
      video: title,
    });
    playerController.goToEnd();
  }, [title]);

  const handleExit = React.useCallback(() => {
    track(events.onboardingVideos.exitVideo, {
      video: title,
    });
    onExit();
  }, [onExit, title]);

  const handleComplete = React.useCallback(() => {
    track(events.onboardingVideos.completeVideo, {
      video: title,
    });
    onComplete();
  }, [onComplete, title]);

  React.useEffect(() => {
    let setKeyboardEvents = false;
    let skipped = false;
    function handlePlayerKeyboardEvents(event: KeyboardEvent) {
      if (!videoElementRef.current) {
        return;
      }

      if (playerController.mounted === false) {
        return;
      }

      // console.log('event', event);
      // Handle space
      if (event.key === ' ') {
        if (playerController.isPlaying) {
          playerController.pause();
        } else {
          playerController.play();
        }
      }

      // making this available n\only when piMenu is not hidden
      // which I guess is a shortcut for god mode
      if (!hidePiMenu() && event.key === 'x') {
        if (skipped) {
          // exit <v></v>ideo
          // onEnded();
          onComplete();
          skipped = false;
        } else {
          // skip video
          onSkip();
          skipped = true;
        }
      }
    }

    if (videoElementRef.current) {
      window.addEventListener('keydown', handlePlayerKeyboardEvents);
      setKeyboardEvents = true;
      playerController.initialize({
        src: videoSrc,
        videoElement: videoElementRef.current,
        onEnded: () => {
          // eslint-disable-next-line no-console
          // console.log('On Ended');
          onEnded();
        },
        onPlay: () => {
          // eslint-disable-next-line no-console
          // console.log('On Play');
        },
        onPause: () => {
          // eslint-disable-next-line no-console
          // console.log('On Pause');
        },
      });

      playerController.videoElement.addEventListener(
        'loadeddata',
        (_event: Event) => {
          ///under certain conditions we want the video to be in the last frame
          if (videoWatchedFn()) {
            playerController.goToEnd();
          }
        }
      );
    }

    return () => {
      if (playerController) {
        // we most likely will want to reset the player state, too
        playerController.reset();
      }

      if (setKeyboardEvents) {
        document.removeEventListener('keydown', handlePlayerKeyboardEvents);
      }
    };
  }, [videoSrc, videoElementRef, videoWatchedFn, onEnded, onComplete, onSkip]);

  return (
    <Backdrop>
      <VideoWrapper>
        <PlayerOverlayCard
          playerController={playerController}
          videoWatched={videoWatched}
          onComplete={handleComplete}
          onExit={handleExit}
          onSkip={onSkip}
          poster={poster}
          title={title}
        />
        <div className="progress-bar">
          <ProgressBar player={playerController} />
        </div>
        <video
          ref={videoElementRef}
          controls={false}
          autoPlay={false}
          disablePictureInPicture={true}
          controlsList="nodownload"
          playsInline
        />
      </VideoWrapper>
    </Backdrop>
  );
};
