import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { learnHomePath, teachHomePath } from './path-helpers';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
import { ExternalNavButton } from './external-nav-button';
import { EduIcon } from '@naan/icons/edu-icon';
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react';
import { JwLogoIcon } from '@naan/icons/jw-logo-icon';

import __ from 'core/lib/localization';

export const TeachNavButton = observer(() => {
  const { userManager } = AppFactory.root;
  const { classroomEnabled } = userManager;

  const navigate = useNavigate();
  const handleGoToClassrooms = React.useCallback(() => {
    const to = teachHomePath();
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate]);

  if (!classroomEnabled) {
    return null;
  }

  return (
    <div className="external">
      <ExternalNavButton
        label={__('Classrooms', 'classrooms')}
        icon={<EduIcon />}
        onClick={handleGoToClassrooms}
      />
    </div>
  );
});

export const LearnNavButton = ({
  flavor = 'normal',
}: {
  flavor?: 'normal' | 'dark';
}) => {
  const navigate = useNavigate();
  const handleGoToMy = React.useCallback(() => {
    const to = learnHomePath();
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate]);

  return (
    <div className="external">
      <ExternalNavButton
        label={__('My Jiveworld', 'myJiveworld')}
        icon={<JwLogoIcon />}
        onClick={handleGoToMy}
        flavor={flavor}
      />
    </div>
  );
};
